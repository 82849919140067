<template>
  <v-card flat>
    <v-card-title class="px-5 d-flex align-center">
      <div class="font-weight-bold">{{ attribute.name }}</div>

      <v-tooltip
        bottom
        color="white"
        content-class="serial-tooltip elevation-4 px-2"
        nudge-top="5px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            class="tp-btn--text green lighten-5 green--text px-2 ml-2"
            label
            small
            v-on="on"
            v-bind="attrs"
            @click="openModalFormAttributeDescription"
          >
            <v-icon color="green" class="mr-1" size="14px"
              >mdi-pencil-outline</v-icon
            >
            <span class="pr-2">
              Thông tin bổ trợ
            </span>
          </v-chip>
        </template>
        <div class="black--text text-body-2" style="width: 200px">
          {{ attribute.attr_details[0].attr_des || "Chưa có dữ liệu" }}
        </div>
      </v-tooltip>
    </v-card-title>

    <v-container class="px-5 pt-0 pb-1">
      <v-row class="">
        <v-col
          v-for="item in attribute.attr_details"
          :key="item.id"
          class="py-0"
          cols="12"
          lg="6"
        >
          <form-attribute-detail
            :data-type="item.data_type"
            :property="item.code"
          ></form-attribute-detail>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import FormAttributeDetail from "./FormAttributeDetail";

export default {
  components: { FormAttributeDetail },

  props: {
    attribute: {
      type: Object,
      required: true
    }
  },

  computed: {
    productOption() {
      return this.$store.getters["PRODUCT_OPTION/productOption"];
    },
    firstProperty() {
      return this.attribute.attr_details[0].code;
    }
  },

  methods: {
    openModalFormAttributeDescription() {
      this.$modal.show({
        name: "modal-form-attribute-description",
        payload: {
          attribute: {
            name: this.attribute.name,
            des: this.productOption.attr_details[this.firstProperty].attr_des
          },
          onAfterSave: des => {
            this.productOption.attr_details[this.firstProperty].attr_des = des;

            this.$set(this.productOption, "attr_details", {
              ...this.productOption.attr_details
            });
          }
        }
      });
    }
  }
};
</script>
