<template>
  <div>
    <div class="d-flex align-center justify-space-between mb-2">
      <div class="font-weight-bold mr-4">
        {{ productOption.attr_details[property].name }}
      </div>

      <v-tooltip nudge-top="5px" top>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-switch
              v-model="productOption.attr_details[property].upgradable"
              class="mt-0 pt-0"
              color="green"
              dense
              hide-details
            ></v-switch>
          </div>
        </template>

        <span>Có thể nâng cấp</span>
      </v-tooltip>
    </div>

    <template v-if="dataType === 'boolean'">
      <v-checkbox
        v-model="productOption.attr_details[property].value"
        :false-value="false"
        :label="productOption.attr_details[property].options"
        :true-value="true"
        class="mt-0 mr-5"
        dense
      ></v-checkbox>
    </template>

    <template v-else-if="dataType === 'array'">
      <v-combobox
        v-model="productOption.attr_details[property].value"
        class="tp-combobox text-body-1"
        deletable-chips
        dense
        hide-selected
        multiple
        outlined
        placeholder="Nhập dữ liệu và enter"
        single-line
        small-chips
        :value="productOption.attr_details[property].value"
        @input.native="
          productOption.attr_details[property].value = $event.srcElement.value
        "
      >
      </v-combobox>
    </template>
    <template v-else>
      <v-combobox
        v-model="productOption.attr_details[property].value"
        :items="productOption.attr_details[property].options"
        class="tp-combobox text-body-1"
        dense
        outlined
        placeholder="Nhập hoặc chọn dữ liệu"
        single-line
        :value="productOption.attr_details[property].value"
        @input.native="
          productOption.attr_details[property].value = $event.srcElement.value
        "
      >
      </v-combobox>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    dataType: {
      type: String,
      required: true
    },
    property: {
      type: String,
      required: true
    }
  },

  computed: {
    productOption() {
      return this.$store.getters["PRODUCT_OPTION/productOption"];
    },

    textFieldType() {
      if (
        this.dataType === "double" ||
        this.dataType === "integer" ||
        this.dataType === "number"
      )
        return "number";

      return null;
    }
  }
};
</script>
