<template>
  <v-card class="px-5 py-4" flat>
    <form-seo-data
      :key="productOption.id"
      v-model="productOption.seo_data"
    ></form-seo-data>
  </v-card>
</template>

<script>
import FormSeoData from "@/core/components/form/FormSeoData/index.vue";

export default {
  components: { FormSeoData },

  computed: {
    productOption() {
      return this.$store.getters["PRODUCT_OPTION/productOption"];
    }
  }
};
</script>
