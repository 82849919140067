<template>
  <tp-modal max-width="1024px" name="modal-product-option" width="80%">
    <v-card class="text-body-1">
      <v-toolbar
        class="px-5 pb-0 align-start"
        elevation="0"
        extension-height="40px"
        height="56x"
        style="position: sticky; top: 0; left: 0; right: 0; z-index: 1"
      >
        <v-toolbar-title class="font-weight-bold">
          Tùy chọn sản phẩm
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          v-if="productOption.id"
          :disabled="
            productOptionStatusRequest.value === 'loading-updateProductOption'
          "
          :loading="
            productOptionStatusRequest.value === 'loading-updateProductOption'
          "
          class="cyan lighten-5 rounded-lg mr-2"
          color="primary"
          text
          @click="updateProductOption()"
        >
          Lưu
        </v-btn>
        <v-btn
          v-else
          :disabled="
            productOptionStatusRequest.value === 'loading-createProductOption'
          "
          :loading="
            productOptionStatusRequest.value === 'loading-createProductOption'
          "
          class="cyan lighten-5 rounded-lg mr-2"
          color="primary"
          text
          @click="createProductOption()"
        >
          Thêm
        </v-btn>
        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <template v-slot:extension>
          <v-tabs v-model="activeTab" height="40px">
            <v-tab
              v-for="item in PRODUCT_OPTION_TABS"
              :key="item.id"
              class="font-weight-bold"
            >
              {{ item.text }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-form ref="productOptionForm">
        <v-tabs-items v-model="activeTab" class="modal-body-scroll">
          <!-- Start: Alert Error -->
          <v-alert
            v-if="
              [
                'error-createProductOption',
                'error-updateProductOption'
              ].includes(productOptionStatusRequest.value) ||
                productStatusRequest.value === 'error-addSpecs'
            "
            class="text-body-1 ma-5"
            dense
            text
            type="error"
          >
            {{
              productOptionStatusRequest.message || productStatusRequest.message
            }}
          </v-alert>
          <!-- End: Alert Error -->
          <v-tab-item key="general" class="grey lighten-3 pa-5">
            <tab-item-general
              @updateFormDataImages="updateFormDataImages($event)"
              @updateModelSiteConfigs="modelSiteConfigs = $event"
            ></tab-item-general>
          </v-tab-item>

          <v-tab-item key="detail" class="grey lighten-3 px-5 pb-5 pt-3">
            <tab-item-detail ref="tabItemDetail"></tab-item-detail>
          </v-tab-item>

          <v-tab-item key="preOrder" class="grey lighten-3 pa-5">
            <tab-pre-order></tab-pre-order>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
    </v-card>
  </tp-modal>
</template>

<script>
import { toFormData } from "@vt7/utils";
import TabItemDetail from "./components/TabItemDetail/index";
import TabItemGeneral from "./components/TabItemGeneral/index";
import TabPreOrder from "./components/TabPreOrder";
import { PRODUCT_OPTION_TABS, LIST_MODEL_TYPES } from "@/core/constant";
import { showModalAlertError } from "@/core/composables";

export default {
  components: {
    TabItemDetail,
    TabItemGeneral,
    TabPreOrder
  },

  data() {
    return {
      formDataImages: null,
      formDataThumbnail: null,
      PRODUCT_OPTION_TABS
    };
  },

  computed: {
    activeTab: {
      get: function() {
        return this.$store.getters["PRODUCT_OPTION/activeTab"];
      },
      set: function(newValue) {
        this.$store.dispatch("PRODUCT_OPTION/setActiveTab", newValue);
      }
    },

    categories() {
      return this.$store.getters["PRODUCT_TYPE/categories"];
    },

    product() {
      return this.$store.getters["PRODUCT/product"];
    },

    productOption() {
      return this.$store.getters["PRODUCT_OPTION/productOption"];
    },

    productOptionModelSiteConfigs() {
      return this.$store.getters[
        "PRODUCT_OPTION/productOptionModelSiteConfigs"
      ];
    },

    productOptionStatusRequest() {
      return this.$store.getters["PRODUCT_OPTION/statusRequest"];
    },

    productStatusRequest() {
      return this.$store.getters["PRODUCT/statusRequest"];
    },

    uploadedProductOptionImages() {
      return this.$store.getters["PRODUCT_OPTION/uploadedProductOptionImages"];
    },

    uploadedThumbnail() {
      return this.$store.getters["PRODUCT/uploadedThumbnail"];
    },

    productModelSiteConfigs() {
      return this.$store.getters["PRODUCT/productModelSiteConfigs"];
    },

    selectedSites() {
      return this.productModelSiteConfigs
        .filter(item => item.available)
        .map(item => item.site);
    }
  },

  methods: {
    closeModal() {
      this.$modal.hide({ name: "modal-product-option" });

      this.$store.dispatch("PRODUCT_OPTION/resetProductOption");

      // Reset model site configs
      this.$store.dispatch(
        "PRODUCT_OPTION/setProductOptionModelSiteConfigs",
        []
      );

      this.activeTab = null;

      this.$refs.productOptionForm.resetValidation();
      if (this.$refs.tabItemDetail) {
        this.$refs.tabItemDetail.selection = 0;
      }
    },

    async onUploadIcon() {
      await this.uploadThumbnail(
        toFormData({
          file: this.productOption.thumbnail
        })
      );

      // Set uploaded thumbnail
      if (this.productStatusRequest.value === "success-uploadThumbnail") {
        this.productOption.thumbnail = this.uploadedThumbnail;
      } else if (this.productStatusRequest.value === "error-uploadThumbnail") {
        showModalAlertError(this, {
          title: "Lỗi tải lên ảnh đại diện",
          message: this.productStatusRequest.message
        });

        return;
      }
    },

    async createProductOption() {
      console.log("create", this.productOption);
      // Validate
      const productOptionFormValid = await this.$refs.productOptionForm.validate();
      if (!productOptionFormValid) return;

      // Check mobile thumbnail changes
      if (this.productOption.thumbnail instanceof File) {
        await this.onUploadIcon();
      }

      // Set product id
      this.productOption.product_id = this.product.id;
      // Request create
      await this.$store.dispatch("PRODUCT_OPTION/createProductOption", {
        productId: this.product.id,
        productOption: this.productOption
      });

      if (
        this.productOptionStatusRequest.value === "success-createProductOption"
      ) {
        await this.$store.dispatch("MODEL_SITE_CONFIG/updateModelSiteConfigs", {
          data: this.productOptionModelSiteConfigs.map(item => {
            item.model_id = this.productOption.id;

            return item;
          }),
          modelId: this.productOption.id,
          modelType: LIST_MODEL_TYPES.PRODUCT_OPTION
        });

        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thêm mới thành công"
          }
        });
      }
    },

    getSitesByCategoryId(categoryId) {
      return this.categories.find(item => item.id === categoryId).sites;
    },

    async updateFormDataImages(val) {
      await this.$store.dispatch(
        "PRODUCT_OPTION/uploadProductOptionImages",
        val
      );
      if (
        this.productOptionStatusRequest.value ===
        "success-uploadProductOptionImages"
      ) {
        this.productOption.images = this.productOption.images.concat(
          this.uploadedProductOptionImages
        );
      }
    },

    async updateProductOption() {
      // Validate
      const productOptionFormValid = await this.$refs.productOptionForm.validate();
      if (!productOptionFormValid) return;

      // Check thumbnail change
      if (this.productOption.thumbnail instanceof File) {
        await this.onUploadIcon();
      }

      // Request update
      await this.$store.dispatch("PRODUCT_OPTION/updateProductOption", {
        productId: this.product.id,
        productOption: this.productOption
      });

      if (
        this.productOptionStatusRequest.value === "success-updateProductOption"
      ) {
        await this.$store.dispatch("MODEL_SITE_CONFIG/updateModelSiteConfigs", {
          data: this.productOptionModelSiteConfigs,
          modelId: this.productOption.id,
          modelType: LIST_MODEL_TYPES.PRODUCT_OPTION
        });

        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã lưu cập nhật"
          }
        });
      }
    },

    async uploadThumbnail(formData) {
      if (!formData) return;

      const payload = {
        formData: formData,
        name: this.productOption.name,
        sites: this.selectedSites.length
          ? this.selectedSites
          : this.getSitesByCategoryId(this.product.category_id)
      };

      await this.$store.dispatch("PRODUCT/uploadThumbnail", payload);
    }
  }
};
</script>
