<template>
  <div v-if="preOrderInfo">
    <v-switch
      v-model="preOrderInfo.isEnabled"
      class="mt-0"
      :label="
        preOrderInfo.isEnabled ? 'Pre-order đang mở' : 'Pre-order đã kết thúc'
      "
    ></v-switch>

    <v-card class="px-5 py-6" flat>
      <v-overlay
        :absolute="true"
        :opacity="0.6"
        :value="!preOrderInfo.isEnabled"
        color="#f7f7f7"
      ></v-overlay>
      <div class="pa-2 yellow lighten-5">
        <span
          class="font-weight-bold
        "
          >Lưu ý:</span
        >
        Cài đặt Pre-order sẽ được ưu tiên, các chính sách khuyến mãi khác sẽ bị
        ẩn đi trên giao diện khách hàng. Nhưng SKU vẫn nằm trong danh sách các
        sản phẩm khuyến mãi của campaign liên quan.
      </div>

      <div class="my-5">
        <div class="font-weight-bold text-subtitle-1">Số lượng</div>

        <pre-order-quantity
          :is-open-pre-order="preOrderInfo.isEnabled"
        ></pre-order-quantity>
      </div>

      <v-divider></v-divider>

      <div class="my-5">
        <div class="font-weight-bold text-subtitle-1">Ưu đãi đặt trước</div>

        <pre-order-offer
          :is-open-pre-order="preOrderInfo.isEnabled"
        ></pre-order-offer>
      </div>

      <v-divider></v-divider>

      <div class="my-5">
        <div class="font-weight-bold text-subtitle-1">Thời gian</div>

        <pre-order-time></pre-order-time>
      </div>
    </v-card>
  </div>
  <div v-else>
    <v-btn color="primary" depressed @click="handleCreateEmptyPreOrder"
      >Tạo Pre-Order</v-btn
    >
  </div>
</template>

<script>
import PreOrderOffer from "./components/PreOrderOffer";
import PreOrderQuantity from "./components/PreOrderQuantity";
import PreOrderTime from "./components/PreOrderTime";
import { INIT_PRODUCT_OPTION_VALUE } from "@/modules/Goods/constants";

export default {
  components: { PreOrderQuantity, PreOrderOffer, PreOrderTime },

  computed: {
    productOption() {
      return this.$store.getters["PRODUCT_OPTION/productOption"];
    },

    preOrderInfo() {
      return this.productOption.pre_order_info;
    }
  },

  data() {
    return {
      INIT_PRODUCT_OPTION_VALUE: INIT_PRODUCT_OPTION_VALUE
    };
  },

  methods: {
    handleCreateEmptyPreOrder() {
      this.productOption.pre_order_info = JSON.parse(
        JSON.stringify(this.INIT_PRODUCT_OPTION_VALUE.pre_order_info)
      );
    }
  }
};
</script>
