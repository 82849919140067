<template>
  <v-card class="px-5 py-4" flat>
    <v-card-title class="font-weight-bold px-0 pt-0">
      Thiết lập bài viết
    </v-card-title>

    <v-checkbox
      v-model="productOption.is_inherit_article"
      class="mt-0"
      label="Sử dụng cùng bài viết với sản phẩm"
    ></v-checkbox>

    <div v-if="!productOption.is_inherit_article">
      <div
        v-for="site in ARRAY_LIST_MODEL_SITES"
        :key="site.id"
        class="py-0"
        cols="6"
      >
        <div class="font-weight-bold mb-2">Bài viết mô tả {{ site.text }}</div>

        <autocomplete-article
          v-model="articles[site.id].article_id"
          :article="articles[site.id].article"
          :site="site.id"
        ></autocomplete-article>
      </div>
    </div>
  </v-card>
</template>

<script>
import AutocompleteArticle from "@/core/components/modals/ModalProduct/components/AutocompleteArticle";
import { ARRAY_LIST_MODEL_SITES } from "@/core/constant";

export default {
  components: { AutocompleteArticle },

  data() {
    return {
      ARRAY_LIST_MODEL_SITES
    };
  },

  computed: {
    articles() {
      return this.productOption.articles.reduce(
        (obj, cur) => ({
          ...obj,
          [cur.site]: cur
        }),
        {}
      );
    },

    productOption() {
      return this.$store.getters["PRODUCT_OPTION/productOption"];
    }
  }
};
</script>
