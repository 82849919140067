<template>
  <v-card class="px-5 py-4" flat>
    <v-card-title class="font-weight-bold px-0 pt-0"
      >Thiết lập website
    </v-card-title>

    <v-row>
      <!-- Start: Sale status -->
      <v-col class="py-0 mb-1" cols="12" md="6">
        <div class="font-weight-bold mb-2">Tình trạng kinh doanh (KD)</div>
        <v-radio-group v-model="productOption.ttkd" class="mt-0" dense row>
          <v-radio :value="1" label="Đang KD"></v-radio>
          <v-radio :value="0" label="Ngừng KD"></v-radio>
        </v-radio-group>
      </v-col>
      <!-- End: Sale status -->

      <!-- Start: Has payment required -->
      <v-col v-if="false" class="py-0 mb-1" cols="12" md="6">
        <div class="font-weight-bold mb-2">Mức độ giữ hàng</div>
        <v-radio-group
          v-model="productOption.has_payment_required"
          class="mt-0"
          dense
          row
        >
          <v-radio :value="0" label="Miễn phí"></v-radio>
          <v-radio :value="1" label="Đặt cọc"></v-radio>
        </v-radio-group>
      </v-col>
      <!-- End: Has payment required -->
    </v-row>
  </v-card>
</template>

<script>
import { ARRAY_LIST_MODEL_SITES } from "@/core/constant";
import { LIST_MODEL_TYPES } from "@/core/constant";

export default {
  props: {
    modelSiteConfigs: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return { ARRAY_LIST_MODEL_SITES, LIST_MODEL_TYPES };
  },

  computed: {
    computedModelSiteConfigs: {
      get() {
        return this.productOptionModelSiteConfigs;
      },
      set(val) {
        this.$store.dispatch(
          "PRODUCT_OPTION/setProductOptionModelSiteConfigs",
          val
        );
      }
    },

    productOption() {
      return this.$store.getters["PRODUCT_OPTION/productOption"];
    },

    productOptionModelSiteConfigs() {
      return this.$store.getters[
        "PRODUCT_OPTION/productOptionModelSiteConfigs"
      ];
    }
  }
};
</script>
