var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"px-5 py-4",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"font-weight-bold px-0 pt-0"},[_c('div',{staticClass:"d-flex align-baseline"},[_c('div',[_vm._v(" Cấu hình rút gọn ")]),_c('v-tooltip',{attrs:{"bottom":"","color":"white","content-class":"serial-tooltip elevation-4 px-2","nudge-top":"5px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":hover ? 'grey darken-1' : 'grey',"size":"14px"}},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}],null,true)})]}}])},[_c('div',{staticClass:"black--text text-body-2",staticStyle:{"width":"240px"}},[_vm._v(" Cấu hình rút gọn mô tả ngắn gọn các thông số kỹ thuật của SKU, thể hiện được sự khác biệt về cấu hình giữa các SKU thuộc cùng một sản phẩm. Các SKU này có thể có chung một cấu hình, tuy nhiên tổ hợp bao gồm "),_c('div',{staticStyle:{"font-weight":"600"}},[_vm._v(" Cấu hình rút gọn, Màu sắc và Tình trạng hàng của một SKU phải là duy nhất trong sản phẩm đó. ")])])])],1)]),_c('div',[_c('div',{staticClass:"specs-group"},[_vm._l((_vm.product.specs),function(spec){return _c('v-hover',{key:spec.id},[_c('v-card',{class:[
            'spec-item',
            { selected: spec.id === _vm.productOption.specs_id }
          ],attrs:{"outlined":"","ripple":false}},[_c('v-textarea',{class:[
              'text-body-1 mb-5 mt-3 px-3',
              { selected: spec.id === _vm.selectedSpecId }
            ],attrs:{"default":"","hide-details":"","no-resize":""},on:{"focus":function($event){return _vm.handleFocusTextarea(spec.id)}},model:{value:(spec.name),callback:function ($$v) {_vm.$set(spec, "name", $$v)},expression:"spec.name"}}),_c('div',{staticClass:"spec-item-wrapper"},[_c('div',{class:[
                'radio',
                { 'radio-checked': spec.id === _vm.productOption.specs_id }
              ],on:{"click":function () { return _vm.handleSelectSpecs(spec.id); }}}),(spec.id === _vm.selectedSpecId)?_c('div',{staticClass:"spec-item-button"},[_c('v-btn',{attrs:{"color":"primary","depressed":"","loading":_vm.productStatusRequest.value === 'loading-editSpecs',"small":""},on:{"click":function($event){return _vm.handleEditSpecs(spec)}}},[_vm._v("Lưu")])],1):_vm._e()])],1)],1)}),_c('v-hover',[_c('v-card',{staticClass:"spec-item",attrs:{"outlined":"","ripple":false}},[_c('v-textarea',{staticClass:"text-body-1 mb-5 mt-3 px-3",attrs:{"default":"","hide-details":"","no-resize":"","placeholder":"Thêm mới cấu hình rút gọn"},model:{value:(_vm.newSpecs),callback:function ($$v) {_vm.newSpecs=$$v},expression:"newSpecs"}}),_c('div',{staticClass:"spec-item-wrapper"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',{staticClass:"spec-item-button"},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":_vm.handleCreateNewSpecs}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]}}])},[_c('span',[_vm._v("Thêm")])])],1)],1)],1)],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }