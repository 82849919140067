<template>
  <div>
    <card-general />

    <card-detail class="mt-5"></card-detail>

    <card-specs v-if="false" class="mt-5"></card-specs>

    <card-website-setting class="mt-5"></card-website-setting>

    <card-gallery
      class="mt-5"
      @updateFormDataImages="updateFormDataImages($event)"
    ></card-gallery>

    <template v-if="false">
      <card-content-setting class="mt-5"></card-content-setting>

      <card-seo-setting class="mt-5"></card-seo-setting>
    </template>

    <modal-form-color></modal-form-color>

    <modal-form-demand></modal-form-demand>
  </div>
</template>

<script>
import CardContentSetting from "./components/CardContentSetting.vue";
import CardSeoSetting from "./components/CardSeoSetting.vue";
import CardDetail from "./components/CardDetail";
import CardGallery from "./components/CardGallery";
import CardGeneral from "./components/CardGeneral";
import CardSpecs from "./components/CardSpecs";
import CardWebsiteSetting from "./components/CardWebsiteSetting";
import ModalFormDemand from "./modals/ModalFormDemand";
import ModalFormColor from "./modals/ModalFormColor";

export default {
  components: {
    CardSeoSetting,
    CardContentSetting,
    CardDetail,
    CardGallery,
    CardGeneral,
    CardSpecs,
    CardWebsiteSetting,
    ModalFormColor,
    ModalFormDemand
  },

  methods: {
    updateFormDataImages(val) {
      this.$emit("updateFormDataImages", val);
    }
  }
};
</script>
