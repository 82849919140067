<template>
  <tp-modal name="modal-form-attribute-description" max-width="480px">
    <v-card v-if="props.payload && props.payload.attribute" slot-scope="props">
      <v-card-title>
        Thông tin bổ trợ của {{ props.payload.attribute.name }}
      </v-card-title>

      <v-card-text class="text-body-1">
        <v-textarea
          class="text-body-1 mt-2"
          auto-grow
          hide-details
          placeholder="Nhập nội dung"
          outlined
          rows="3"
          single-line
          :value="props.payload.attribute.des"
          @input="description = $event"
        ></v-textarea>
      </v-card-text>

      <v-card-actions class="px-5 pb-4 pt-0">
        <v-spacer></v-spacer>
        <v-btn text color="grey darken-1" @click="closeModal">
          Bỏ qua
        </v-btn>

        <v-btn
          text
          color="primary"
          @click="onSave(props.payload.onAfterSave || undefined)"
        >
          Lưu
        </v-btn>
      </v-card-actions>
    </v-card>
  </tp-modal>
</template>

<script>
export default {
  data() {
    return {
      description: null
    };
  },

  methods: {
    closeModal() {
      this.$modal.hide({ name: "modal-form-attribute-description" });

      this.description = null;
    },

    async onSave(onAfterSave) {
      this.$toast.show({
        name: "toast-action-alert",
        payload: {
          message: "Cập nhật thông tin bổ trợ thành công"
        }
      });

      if (typeof onAfterSave === "function") {
        onAfterSave(this.description);
      }

      this.closeModal();
    }
  }
};
</script>

<style lang="scss" scoped></style>
