<template>
  <div>
    <v-chip-group
      v-model="selection"
      active-class="primary--text"
      class="mb-3"
      mandatory
      show-arrows
    >
      <v-chip
        v-for="attribute in productOptionAttributes"
        color="white"
        :key="'tab-' + attribute.code"
      >
        {{ attribute.name }}
      </v-chip>
    </v-chip-group>

    <card-attribute
      :attribute="selectedProductOptionAttribute"
    ></card-attribute>

    <modal-form-attribute-description></modal-form-attribute-description>
  </div>
</template>

<script>
import CardAttribute from "./components/CardAttribute";
import ModalFormAttributeDescription from "./modals/ModalFormAttributeDescription";

import { groupByProperty } from "@/core/utils/array";

export default {
  components: {
    CardAttribute,
    ModalFormAttributeDescription
  },
  data() {
    return {
      selection: 0
    };
  },
  computed: {
    productOption() {
      return this.$store.getters["PRODUCT_OPTION/productOption"];
    },
    productOptionAttributes() {
      const arr = groupByProperty(
        Object.values(this.productOption.attr_details),
        "attr_id"
      );

      return arr.map(item => {
        return {
          ...item[0].attr,
          attr_details: item
        };
      });
    },
    selectedProductOptionAttribute() {
      return this.productOptionAttributes[this.selection];
    }
  }
};
</script>
