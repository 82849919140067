<template>
  <tp-modal max-width="480px" name="modal-form-demand" width="480px">
    <v-card slot-scope="props">
      <v-card-title>
        {{ demand.id ? "Sửa nhu cầu" : "Thêm nhu cầu" }}
      </v-card-title>

      <v-card-text class="text-body-1">
        <v-form ref="form">
          <tp-text-field
            v-model="demand.name"
            class="mt-2"
            dense
            label="Tên nhu cầu"
            outlined
            placeholder="Nhập tên nhu cầu"
            validate="required"
          ></tp-text-field>

          <tp-text-field
            v-model="demand.des"
            class="mt-5"
            dense
            label="Mô tả"
            outlined
            placeholder="Nhập mô tả"
            validate="required"
          ></tp-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions class="px-5 pb-4 pt-0">
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="closeModal">
          Bỏ qua
        </v-btn>

        <v-btn
          v-if="demand.id"
          :loading="demandStatusRequest.value === 'loading-updateDemand'"
          color="primary"
          text
          @click="
            demandStatusRequest.value === 'loading-updateDemand'
              ? null
              : updateDemand(props.payload.onSuccessfulUpdate || undefined)
          "
        >
          Lưu
        </v-btn>

        <v-btn
          v-else
          :loading="demandStatusRequest.value === 'loading-createDemand'"
          color="primary"
          text
          @click="
            demandStatusRequest.value === 'loading-createDemand'
              ? null
              : createDemand(props.payload.onSuccessfulCreate || undefined)
          "
        >
          Thêm
        </v-btn>
      </v-card-actions>
    </v-card>
  </tp-modal>
</template>

<script>
export default {
  computed: {
    demand() {
      return this.$store.getters["DEMAND/demand"];
    },
    demandStatusRequest() {
      return this.$store.getters["DEMAND/statusRequest"];
    }
  },

  methods: {
    closeModal() {
      this.$modal.hide({ name: "modal-form-demand" });
    },

    async createDemand(onSuccessfulCreate) {
      const isValid = this.$refs.form.validate();

      if (!isValid) return;

      await this.$store.dispatch("DEMAND/createDemand", { ...this.demand });

      if (this.demandStatusRequest.value === "success-createDemand") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thêm mới nhu cầu thành công"
          }
        });

        if (typeof onSuccessfulCreate === "function") {
          onSuccessfulCreate(this.demand);
        }

        this.closeModal();
      }
    },

    async updateDemand(onSuccessfulUpdate) {
      const isValid = this.$refs.form.validate();

      if (!isValid) return;

      await this.$store.dispatch("DEMAND/updateDemand", { ...this.demand });

      if (this.demandStatusRequest.value === "success-updateDemand") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Cập nhật nhu cầu thành công"
          }
        });

        if (typeof onSuccessfulUpdate === "function") {
          onSuccessfulUpdate(this.demand);
        }

        this.closeModal();
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
