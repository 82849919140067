<template>
  <v-card class="px-5 py-4" flat>
    <v-card-title class="font-weight-bold px-0 pt-0"
      >Thông tin chung
    </v-card-title>

    <div class="d-flex">
      <!-- Start: Avatar -->
      <div class="pr-8">
        <div class="font-weight-bold mb-2">Ảnh đại diện</div>
        <div class="card-general-image">
          <tp-input-image
            :height="256 * 2"
            :maxFileSize="300 * 1024"
            :src.sync="productOption.thumbnail"
            :width="256 * 2"
            allowCompress
            allowFileDimensionValidation
            allowFileSize
            allowFileTypeValidation
            class="flex-grow-1"
            forceType="png"
            required
          ></tp-input-image>
        </div>
      </div>
      <!-- End: Avatar -->

      <v-row class="my-0">
        <!-- Start: Name -->
        <v-col class="py-0" cols="12">
          <div class="font-weight-bold mb-2">Tên hiển thị</div>
          <tp-text-field
            v-model="productOption.name"
            placeholder="Được tạo tự động sau khi thêm tùy chọn"
            readonly
          ></tp-text-field>
        </v-col>
        <!-- End: Name -->
        <!-- Start: SKU -->
        <v-col class="py-0" cols="12" sm="6">
          <div class="font-weight-bold mb-2">SKU</div>
          <tp-text-field
            v-model="productOption.SKU"
            placeholder="Nhập SKU"
            validate="required"
          ></tp-text-field>
        </v-col>
        <!-- End: SKU -->
        <v-col class="py-0" cols="12" sm="6">
          <div class="font-weight-bold mb-2">MPN</div>
          <tp-text-field
            v-model="productOption.MPN"
            placeholder="Nhập MPN"
          ></tp-text-field>
        </v-col>
        <!-- End: MPN -->

        <v-col class="py-0" cols="12" sm="6">
          <div class="font-weight-bold mb-2">Phiên bản</div>

          <tp-select
            v-model="productOption.specs"
            :items="product.specs"
            hide-details
            item-text="name"
            item-value="name"
          ></tp-select>
        </v-col>

        <v-col class="py-0" cols="12" sm="6">
          <div class="font-weight-bold mb-2">Tên model</div>
          <tp-text-field
            v-model="productOption.model_name"
            placeholder="Nhập tên model"
          ></tp-text-field>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    productOption() {
      return this.$store.getters["PRODUCT_OPTION/productOption"];
    },
    product() {
      return this.$store.getters["PRODUCT/product"];
    }
  },
  methods: {}
};
</script>

<style scoped>
.card-general-image {
  width: 136px;
}
</style>
