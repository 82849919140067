<template>
  <v-card class="px-5 py-4" flat>
    <v-card-title class="font-weight-bold px-0 pt-0"
      ><div class="d-flex align-baseline">
        <div>
          Cấu hình rút gọn
        </div>

        <v-tooltip
          bottom
          color="white"
          content-class="serial-tooltip elevation-4 px-2"
          nudge-top="5px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-hover v-slot:default="{ hover }">
              <v-icon
                :color="hover ? 'grey darken-1' : 'grey'"
                class="ml-1"
                size="14px"
                v-bind="attrs"
                v-on="on"
                >mdi-information</v-icon
              >
            </v-hover>
          </template>
          <div class="black--text text-body-2" style="width: 240px">
            Cấu hình rút gọn mô tả ngắn gọn các thông số kỹ thuật của SKU, thể
            hiện được sự khác biệt về cấu hình giữa các SKU thuộc cùng một sản
            phẩm. Các SKU này có thể có chung một cấu hình, tuy nhiên tổ hợp bao
            gồm

            <div style="font-weight: 600">
              Cấu hình rút gọn, Màu sắc và Tình trạng hàng của một SKU phải là
              duy nhất trong sản phẩm đó.
            </div>
          </div>
        </v-tooltip>
      </div>
    </v-card-title>

    <div>
      <div class="specs-group">
        <v-hover v-for="spec in product.specs" :key="spec.id">
          <v-card
            :class="[
              'spec-item',
              { selected: spec.id === productOption.specs_id }
            ]"
            outlined
            :ripple="false"
          >
            <v-textarea
              v-model="spec.name"
              :class="[
                'text-body-1 mb-5 mt-3 px-3',
                { selected: spec.id === selectedSpecId }
              ]"
              default
              hide-details
              no-resize
              @focus="handleFocusTextarea(spec.id)"
            ></v-textarea>

            <div class="spec-item-wrapper">
              <div
                :class="[
                  'radio',
                  { 'radio-checked': spec.id === productOption.specs_id }
                ]"
                @click="() => handleSelectSpecs(spec.id)"
              ></div>

              <div v-if="spec.id === selectedSpecId" class="spec-item-button">
                <v-btn
                  color="primary"
                  depressed
                  :loading="productStatusRequest.value === 'loading-editSpecs'"
                  small
                  @click="handleEditSpecs(spec)"
                  >Lưu</v-btn
                >
              </div>
            </div>
          </v-card>
        </v-hover>

        <v-hover>
          <v-card class="spec-item" outlined :ripple="false">
            <v-textarea
              v-model="newSpecs"
              class="text-body-1 mb-5 mt-3 px-3"
              default
              hide-details
              no-resize
              placeholder="Thêm mới cấu hình rút gọn"
            ></v-textarea>

            <div class="spec-item-wrapper">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div class="spec-item-button">
                    <v-btn
                      icon
                      @click="handleCreateNewSpecs"
                      small
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon>mdi-plus</v-icon></v-btn
                    >
                  </div>
                </template>

                <span>Thêm</span>
              </v-tooltip>
            </div>
          </v-card>
        </v-hover>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      newSpecs: null,
      selectedSpecId: null
    };
  },

  computed: {
    product() {
      return this.$store.getters["PRODUCT/product"];
    },

    productOption() {
      return this.$store.getters["PRODUCT_OPTION/productOption"];
    },

    productStatusRequest() {
      return this.$store.getters["PRODUCT/statusRequest"];
    }
  },

  methods: {
    async handleCreateNewSpecs() {
      await this.$store.dispatch("PRODUCT/addSpecs", {
        specsable_id: this.product.id,
        name: this.newSpecs
      });

      // refetch api to update value
      await this.$store.dispatch("PRODUCT/getProductById", this.product.id);

      this.newSpecs = null;
    },

    async handleEditSpecs(spec) {
      await this.$store.dispatch("PRODUCT/editSpecs", {
        id: spec.id,
        name: spec.name
      });
      this.selectedSpecId = null;

      this.$toast.show({
        name: "toast-action-alert",
        payload: {
          message: "Đã lưu chỉnh sửa cấu hình rút gọn"
        }
      });
    },

    handleSelectSpecs(selectedSpecsId) {
      if (this.productOption.specs_id === selectedSpecsId) {
        this.productOption.specs_id = null;
      } else {
        this.productOption.specs_id = selectedSpecsId;
      }
    },

    handleFocusTextarea(specId) {
      this.selectedSpecId = specId;
    }
  }
};
</script>

<style lang="scss" scoped>
.specs-group {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.spec-item {
  width: 190px;
  position: relative;
  padding: 0px 12px 20px 12px;

  .spec-item-button {
    position: absolute;
    right: 6px;
    bottom: 6px;
  }

  .radio {
    position: absolute;
    left: 16px;
    bottom: 10px;
    padding: 8px;
    background-color: #fff;
    border: 2px solid #797979;
    cursor: pointer;
  }

  .radio-checked {
    border: 2px solid #0065ee;
  }
  .radio-checked::before {
    content: "";
    position: absolute;
    background-color: #0065ee;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
  }
}

.selected {
  box-shadow: #0065ee62 0px 0px 0px 2px;
}
</style>
