<template>
  <v-card class="px-5 py-4" flat>
    <v-card-title class="font-weight-bold px-0 pt-0"
      >Thông tin chi tiết</v-card-title
    >

    <v-row>
      <!-- Start: Color -->
      <v-col class="py-0" cols="4">
        <div class="font-weight-bold mb-2">Màu sắc</div>

        <v-autocomplete
          v-model="productOption.color_id"
          :items="['add', ...colors]"
          background-color="white"
          class="text-body-1"
          dense
          filled
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          outlined
          placeholder="Chọn màu sắc"
          single-line
        >
          <template v-slot:item="data">
            <v-btn
              v-if="data.item === 'add'"
              block
              class="grey lighten-4"
              plain
              small
              text
              @click.stop="openModalFormColor"
            >
              <v-icon left>mdi-plus</v-icon>
              Thêm màu
            </v-btn>
            <v-list-item-title v-else class="d-flex align-center">
              <div class="d-flex align-center py-1">
                <div
                  :style="{
                    backgroundColor: data.item.code || 'white'
                  }"
                  class="color-preview d-flex align-center justify-center mr-3"
                >
                  {{ !data.item.code ? "?" : null }}
                </div>
                <div>
                  <div>{{ data.item.name }}</div>
                  <div class="text-body-2 grey--text text--darken-2">
                    {{ data.item.code || "Chưa có mã màu" }}
                  </div>
                </div>
              </div>

              <v-spacer></v-spacer>

              <v-btn icon small @click.stop="openModalFormColor(data.item)">
                <v-icon color="grey" size="16px">mdi-pencil</v-icon>
              </v-btn>
            </v-list-item-title>
          </template>
        </v-autocomplete>
      </v-col>
      <!-- End: Color -->

      <!-- Start: Source -->
      <v-col class="py-0" cols="4">
        <div class="font-weight-bold mb-2">Nguồn hàng</div>
        <v-radio-group
          v-model="productOption.nguon_hang"
          class="mt-3"
          dense
          row
        >
          <v-radio :value="5" label="Chính hãng"></v-radio>
          <v-radio :value="6" label="Nhập khẩu"></v-radio>
        </v-radio-group>
      </v-col>
      <!-- End: Source -->

      <!-- Start: Usage status -->
      <v-col class="py-0" cols="4">
        <div class="font-weight-bold mb-2">Tình trạng máy</div>
        <v-autocomplete
          v-model="productOption.tinh_trang"
          :items="usingStatus"
          class="tp-filter-autocomplete"
          dense
          hide-selected
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          outlined
          placeholder="Chọn tình trạng máy"
          single-line
        ></v-autocomplete>
      </v-col>
      <!-- End: Usage status -->

      <!-- Start: Price -->
      <v-col class="py-0" cols="4">
        <div class="d-flex justify-space-between">
          <div class="font-weight-bold mb-2">Giá bán</div>
          <v-checkbox
            v-model="productOption.none_price"
            :false-value="0"
            :true-value="1"
            class="mt-0 pt-0"
            color="primary"
            dense
            hide-details
            label="Chưa có giá"
          ></v-checkbox>
        </div>
        <tp-input-price
          v-model="productOption.price"
          :empty-value="null"
          :validate="productOption.none_price ? '' : 'required'"
          custom-class="text-body-1 mt-0 pt-0"
          placeholder="Nhập giá bán"
        ></tp-input-price>
      </v-col>
      <!-- End: Price -->

      <!-- Start: Release date -->
      <v-col class="py-0" cols="4">
        <div class="font-weight-bold mb-2">Ngày ra mắt</div>
        <tp-input-date
          :max="productOption.eol_date"
          :value="productOption.released_date"
          append-icon="mdi-calendar"
          clearable
          custom-class="text-body-1"
          dense
          outlined
          placeholder="Chọn ngày"
          @change="productOption.released_date = $event"
        ></tp-input-date>
      </v-col>
      <!-- End: Release date  -->

      <!-- Start: End of life date -->
      <v-col class="py-0" cols="4">
        <div class="font-weight-bold mb-2">Kết thúc vòng đời</div>
        <tp-input-date
          :min="productOption.released_date"
          :value="productOption.eol_date"
          append-icon="mdi-calendar"
          clearable
          custom-class="text-body-1"
          dense
          outlined
          placeholder="Chọn ngày"
          @change="productOption.eol_date = $event"
        ></tp-input-date>
      </v-col>
      <!-- End: End of life date -->

      <!-- Start: Guarantee package -->
      <v-col class="py-0" cols="12">
        <div class="font-weight-bold mb-2">Gói bảo hành</div>
        <v-autocomplete
          v-model="productOption.guarantee_id"
          :items="guaranteePackages"
          class="tp-filter-autocomplete"
          dense
          hide-selected
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          outlined
          placeholder="Chọn gói bảo hành"
          single-line
        ></v-autocomplete>
      </v-col>
      <!-- End: Guarantee package -->

      <!-- Start: Demand Points -->
      <v-col v-if="product.category_id === 1" class="py-0" cols="12">
        <div class="font-weight-bold mb-2">Điểm số các nhu cầu</div>
        <v-row class="my-0">
          <v-col
            v-for="(item, index) in productOption.demands"
            :key="item.id"
            class="py-0"
          >
            <tp-text-field
              v-model="productOption.demands[index].points"
              :label="item.name"
              placeholder="Điểm số"
              type="number"
              validate="minVal:1|maxVal:10"
            ></tp-text-field>
          </v-col>
        </v-row>
      </v-col>
      <!-- End: Demand Points -->
    </v-row>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      usingStatus: [
        { id: 1, name: "New Sealed" },
        { id: 2, name: "New, Fullbox" },
        { id: 3, name: "New, Outlet" },
        { id: 4, name: "Used" }
      ]
    };
  },

  computed: {
    colors() {
      return this.$store.getters["COLOR/colors"];
    },
    demands() {
      return this.$store.getters["DEMAND/demands"];
    },
    guaranteePackages() {
      return this.$store.getters["GUARANTEE_PACKAGE/allGuaranteePackages"];
    },
    product() {
      return this.$store.getters["PRODUCT/product"];
    },
    productOption() {
      return this.$store.getters["PRODUCT_OPTION/productOption"];
    }
  },

  async created() {
    if (this.guaranteePackages.length === 0) {
      await this.$store.dispatch("GUARANTEE_PACKAGE/getAllGuaranteePackages");
    }

    await this.$store.dispatch("COLOR/getColors");
  },

  methods: {
    openModalFormColor(item) {
      if (item) {
        this.$store.dispatch("COLOR/setColor", { ...item });
      } else {
        this.$store.dispatch("COLOR/resetColor");
      }

      this.$modal.show({
        name: "modal-form-color",
        payload: {
          onSuccessfulCreate: color => {
            this.productOption.color_id = color.id;
          },
          onSuccessfulUpdate: color => {
            this.productOption.color_id = color.id;
          }
        }
      });
    },

    openModalFormDemand(item) {
      if (item) {
        this.$store.dispatch("DEMAND/setDemand", { ...item });
      } else {
        this.$store.dispatch("DEMAND/resetDemand");
      }

      this.$modal.show({
        name: "modal-form-demand",
        payload: {
          onSuccessfulCreate: demand => {
            this.productOption.demand_id = demand.id;
          },
          onSuccessfulUpdate: demand => {
            this.productOption.demand_id = demand.id;
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.color-preview {
  border: 1px solid #e5e5e5;
  height: 32px;
  width: 26px;
}
</style>
